.notFoundContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30vw;
}

.notFoundHead {
  margin-top: 5vw;
  margin-left: 2vw;
  font-family: "Share Tech Mono", monospace;
  font-size: x-large;
}

.notFoundBody {
  margin: 2vw;
  margin-left: 2vw;
  font-family: "Share Tech Mono", monospace;
  font-size: large;
  text-align: center;
}

.notFoundBtn {
  position: static;
  margin-top: 10vw;
}

@media only screen and (max-width: 800px) {
  .notFoundContainer {
    margin-top: 40vw;
  }
}
