.awssld {
  margin-top: 3vw;
  height: 35vw;
  width: 100vw;
  --organic-arrow-color: white !important;
  --control-bullet-color: white !important;
  --control-bullet-active-color: white !important;
  --content-background-color: transparent !important;
  opacity: 0;
  animation: text-flicker 1.5s linear 1, fadeIn 0.5s 1.5s;
  animation-fill-mode: forwards;
}

.awssld__controls__arrow-right {
  color: white;
}

.projectTile {
  display: flex;
  height: 35vw;
  width: 100vw;
  justify-content: center;
  text-align: center;
  text-shadow: none;
}

.projectImage {
  position: absolute;
  height: 35vw;
  width: 100vw;
  object-fit: cover;
}

.projectTitle {
  position: absolute;
  z-index: 1;
  font-size: 6vw;
  background-color: transparent;
  margin-top: 10vw;
  font-family: "Major Mono Display", monospace;
  text-shadow: 2px 2px 4px #000000;
}

.overlay {
  position: absolute;
  width: 100vw;
  height: 35vw;
  background-color: black;
  opacity: 0.7;
}

.projectBlurb {
  z-index: 2;
  margin-top: 20vw;
  font-family: "Share Tech Mono", monospace;
  font-size: 2vw;
  text-shadow: 2px 2px 4px #000000;
}

.projectBlurb a {
  text-decoration: underline;
  line-height: 3rem;
}

@media only screen and (max-width: 800px) {
  .awssld__wrapper {
    margin-top: 20vw;
  }

  .projectsContainer,
  .projectTile,
  .projectImage,
  .overlay,
  .awssld__content,
  .awssld {
    height: 90vw;
  }

  .projectTile {
    align-items: center;
  }

  .projectTitle {
    margin-top: -25vw;
    font-size: 10vw;
  }

  .projectBlurb {
    font-size: 4vw;
  }
}
