/* share-tech-mono-regular - latin */
@font-face {
  font-family: "Share Tech Mono";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("./styles/fonts/share-tech-mono-v10-latin-regular.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./styles/fonts/share-tech-mono-v10-latin-regular.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* major-mono-display-regular - latin */
@font-face {
  font-family: "Major Mono Display";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("./styles/fonts/major-mono-display-v5-latin-regular.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./styles/fonts/major-mono-display-v5-latin-regular.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* audiowide-regular - latin */
@font-face {
  font-family: "Audiowide";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("./styles/fonts/audiowide-v9-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./styles/fonts/audiowide-v9-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

* {
  margin: 0;
  padding: 0;
  color: white;
}

body {
  background-color: black;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

#page {
  width: 100vw;
  min-height: 100vh;
}

#bg {
  position: fixed;
  z-index: -1;
  min-width: 100%;
  min-height: 100%;
}

.navigation {
  padding-top: 10px;
  text-align: center;
}

#content {
  height: 100vw;
}
