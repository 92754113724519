.contactContainer {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 50vw;
  margin-top: 18vw;
  text-align: center;
}

.topRow,
.bottomRow {
  display: flex;
  flex-direction: row;
}

.topRow {
  justify-content: space-evenly;
}
.bottomRow {
  justify-content: center;
}

.contactTile {
  height: 15vw;
  width: 20vw;
  margin: -5vw -11vw;
  font-family: "Share Tech Mono";
}

.contactTile h3 {
  margin-top: 5.5vw;
}

.contactTile a {
  display: inline-block;
  font-size: 1vw;
  margin-top: 5px;
}

.contactTile img {
  display: block;
  clear: both;
  width: 50%;
  margin: auto;
  margin-top: 0.8vw;
}

#linkedin {
  opacity: 0;
  animation: text-flicker 0.5s linear 0.3s 1;
  animation-fill-mode: forwards;
  text-shadow: none !important;
}

#linkedin:hover {
  z-index: 20;
}

#github {
  opacity: 0;
  animation: text-flicker 1s linear 0.5s 1;
  animation-fill-mode: forwards;
  margin-top: -10vw;
  text-shadow: none !important;
}

#github:hover {
  z-index: 20;
}

#email {
  opacity: 0;
  animation: text-flicker 1.5s linear 0.7s 1;
  animation-fill-mode: forwards;
  text-shadow: none !important;
}

#email:hover {
  z-index: 20;
}

#github h3,
#email h3,
#linkedin h3 {
  text-shadow: none !important;
}

.contactTile img {
  height: 2vw;
  width: 2vw;
}

@media only screen and (max-width: 800px) {
  .contactContainer {
    display: flex;
    height: 100vw;
    flex-direction: column;
    justify-content: start;
    margin-top: 20vw;
  }

  .topRow {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contactTile {
    height: 27vw;
    width: 32vw;
    margin: 5px;
  }

  .contactTile a {
    font-size: 2vw;
  }

  #github {
    margin: 0;
  }

  .contactTile img {
    height: 4vw;
    width: 4vw;
  }
}

@media only screen and (max-width: 430px) {
  .contactContainer {
    margin-top: 10vw;
  }
  .contactTile {
    justify-content: center;
    height: 38vw;
    width: 43vw;
    margin: 5px;
  }

  .contactTile h3 {
    font-size: 6vw;
    margin-top: 8vw;
  }

  .contactTile a {
    font-size: 4vw;
  }

  #email a {
    font-size: 3vw;
  }

  .contactTile img {
    height: 5vw;
    width: 5vw;
  }
}
