li {
  display: inline;
  padding: 10px;
  padding-right: 20px;
}

li a {
  display: inline-block;
}
.grow {
  transition: transform 0.2s ease-in-out;
}

.grow:hover {
  transform: scale(1.2);
}

.active {
  font-weight: bolder;
  text-decoration: underline !important;
}

.navLink {
  text-decoration: none;
  font-size: 1.5vw;
  font-family: "Major Mono Display", monospace;
}

@media only screen and (max-width: 800px) {
  .navLink {
    font-size: 3vw;
  }
}
