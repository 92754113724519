.cardContainer {
  position: relative;
  /* background-color: white; */
  height: 35vw;
  width: 70vw;
  margin: 3vw auto;
  overflow: hidden;
}

.images img {
  position: absolute;
  height: 10vw;
  width: 10vw;
  left: 0;
  border: 4px solid #fff;
  margin-left: 15vw;
  margin-top: 6.5vw;
  opacity: 0;
  object-fit: cover;
  animation: text-flicker 1.5s linear 0.3s 1, fadeIn 1s 1.5s;
  animation-fill-mode: forwards;
}

.top:hover {
  animation: fadeIn 1.5s 1;
}

@keyframes text-flicker {
  0% {
    opacity: 0.1;
    text-shadow: 0px 0px 29px rgba(22, 22, 22, 1);
  }

  2% {
    opacity: 1;
    text-shadow: 0px 0px 29px rgba(22, 22, 22, 1);
  }
  8% {
    opacity: 0.1;
    text-shadow: 0px 0px 29px rgba(22, 22, 22, 1);
  }
  9% {
    opacity: 1;
    text-shadow: 0px 0px 29px rgba(22, 22, 22, 1);
  }
  12% {
    opacity: 0.1;
    text-shadow: 0px 0px rgba(22, 22, 22, 1);
  }
  20% {
    opacity: 1;
    text-shadow: 0px 0px 29px rgba(22, 22, 22, 1);
  }
  25% {
    opacity: 0.3;
    text-shadow: 0px 0px 29px rgba(22, 22, 22, 1);
  }
  30% {
    opacity: 1;
    text-shadow: 0px 0px 29px rgba(22, 22, 22, 1);
  }

  70% {
    opacity: 0.7;
    text-shadow: 0px 0px 29px rgba(22, 22, 22, 1);
  }

  72% {
    opacity: 0.2;
    text-shadow: 0px 0px 29px rgba(22, 22, 22, 1);
  }

  77% {
    opacity: 0.9;
    text-shadow: 0px 0px 29px rgba(22, 22, 22, 1);
  }
  100% {
    opacity: 0.9;
    text-shadow: 0px 0px 29px rgba(22, 22, 22, 1);
  }
}

.details {
  float: left;
  padding: 10px;
  margin-top: 4vw;
  margin-left: 27vw;
  line-height: 3vw;
  opacity: 0;
  animation: text-flicker 1.5s linear 1.2s 1;
  animation-fill-mode: forwards;
}

.detailTitle {
  float: left;
  color: white;
  font-size: 1.4vw;
}

.detailAnswer {
  float: left;
  color: white;
  font-weight: lighter;
  font-size: 1.4vw;
  padding-left: 5px;
}

.detailAnswer2 {
  color: white;
  font-weight: lighter;
  font-size: 1.4vw;
}
.detailSection {
  clear: left;
  font-family: "Share Tech Mono", monospace;
}

.Typewriter span {
  color: white;
  font-size: 1.4vw;
}

.btnContainer {
  position: absolute;
  margin: 0 auto;
  bottom: 6vw;
  right: 12vw;
  font-size: 2vw;
  color: white;
  width: 20%;
  padding: 3px;
  text-align: center;
  background-color: #3c3c3c;
  font-family: "Share Tech Mono", monospace;
  opacity: 0;
  text-shadow: none !important;

  animation: text-flicker 1.5s linear 1.5s 1;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.btn {
  color: #fff;
  transition: all 0.5s;
  position: relative;
  cursor: pointer;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  text-shadow: none !important;
}

a {
  text-decoration: none;
}

.btn span {
  width: 100%;
}
.btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.3s;
}
.btn:hover::before {
  opacity: 0;
  transform: scale(0.5, 0.5);
}
.btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  transform: scale(1.2, 1.2);
}
.btn:hover::after {
  opacity: 1;
  transform: scale(1, 1);
}

.btnContainer:active .toggle {
  transform: rotateY(180deg);
}

.hidden {
  display: none;
}

.btnContainer .btn,
.btnContainer .after {
  text-shadow: none !important;
}

@media only screen and (max-width: 800px) {
  .cardContainer {
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 100vw;
    width: 80vw;
    margin-top: 4em;
  }
  .images img {
    height: 20vw;
    width: 20vw;
    left: 15vw;
  }

  .details {
    margin-top: 28vw;
    margin-left: 5vw;
    line-height: 2em;
    padding-bottom: 0;
  }
  .detailTitle,
  .detailAnswer,
  .detailAnswer2 {
    font-size: 3vw;
  }

  .Typewriter span {
    font-size: 3vw;
  }

  .btnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    right: 27vw;
    bottom: 30vw;
    margin-top: 5vw;
    text-align: center;
    width: 30%;
    height: 5vw;
    font-size: 4vw;
  }
}

@media only screen and (max-width: 460px) {
  .btnContainer {
    bottom: 10vw;
  }
}
